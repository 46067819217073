<template>
  <div>
    <div class="bidd">
      <div class="biddings">
        <div class="biddings_title">
          <div class="biddings_name">挂牌采购</div>
          <div class="release hy-btn" @click="addClick">发布挂牌采购信息</div>
        </div>

        <div
          class="item"
          v-for="(item, index) in readlist"
          :key="index"
          @click="ItemClick(item)"
        >
          <div class="item_title">
            <div class="item_goodsName">
              <div>
                <el-tooltip effect="dark" placement="top-start">
                  <div slot="content">
                    <span
                      v-for="(goodsName, index) in item.goodsCategoryList"
                      :key="index"
                      >{{ goodsName.goodsName }} &nbsp;
                      {{
                        index == item.goodsCategoryList.length - 1 ? "" : "|"
                      }}&nbsp;</span
                    >
                  </div>
                  <div>
                    <span
                      v-for="(goodsName, index) in item.goodsCategoryList"
                      :key="index"
                      >{{ goodsName.goodsName }} &nbsp;
                      {{
                        index == item.goodsCategoryList.length - 1 ? "" : "|"
                      }}&nbsp;</span
                    >
                  </div>
                </el-tooltip>
              </div>
              <div
                style="color: red"
                v-if="item.goodsStatus == 0 && item.auditStatus == 2"
              >
                审核拒绝
              </div>
              <div
                style="color: red"
                v-if="item.goodsStatus == 0 && item.auditStatus == 0"
              >
                待审核
              </div>
              <div class="" style="color: red" v-if="item.goodsStatus == 1">
                已关闭
              </div>
            </div>
            <div style="display: flex">
              <div style="line-height: 40px; margin-right: 10px">
                {{ item.createTime }}
              </div>
              <div
                class="title_but"
                style="border: 1px solid #ccc"
                @click.stop="closeDemand(item)"
                v-if="item.auditStatus == 0&&item.goodsStatus==0||item.auditStatus==1&&item.goodsStatus==0"
              >
                关闭需求
              </div>
            </div>
          </div>
          <div class="item_name">
            <div class="item_images">
              <span v-if="item.taxIncluded == 0">含税</span>
              <span v-if="item.taxIncluded == 1">不含税</span>
              <span v-if="item.priceType == 0">未知</span>
              <span v-if="item.priceType == 1">坑口价</span>
              <span v-if="item.priceType == 2">出厂价</span>
              <span v-if="item.priceType == 3">船板价</span>
              <span v-if="item.priceType == 4">平仓价</span>
              <span v-if="item.priceType == 5">车板价</span>
              <span v-if="item.priceType == 6">到厂价</span>
            </div>
            <div style="margin-left: 20px">
              价格：<span style="color: #d0111a" v-if="item.goodsCategoryList.length==1">￥</span>
              <span style="color: #d0111a; font-size: 30px">{{
                item.goodsCategoryList.length>1?'详情查看': item.goodsCategoryList[0].goodsPrice == 0 ? "面议" : item.goodsCategoryList[0].goodsPrice
              }}</span>
              <span v-if="item.goodsCategoryList.length==1&&item.goodsCategoryList[0].goodsPrice != 0">/吨</span>
            </div>
            <div style="line-height: 33px; margin-left: 20px">
              <span>初始数量:</span>
              <span style="font-size: 30px; color: #d0111a" v-if="item.ifConsultRealityCount==0">{{
                item.initialCount
              }}吨</span
              >
              <span v-else style="font-size:20px">以实际结算数量为准</span>
            </div>
            <div style="line-height: 33px; margin-left: 20px" v-if="item.initialCount>0">
              <span>库存数量</span>
              <span style="font-size: 30px; color: #d0111a">{{
                item.stockCount
              }}</span
              >吨
            </div>
          </div>
          <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
          <div class="place">产地：{{ item.originPlace }}</div>
        </div>
        <div class="allpage" v-if="readlist.length > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 挂牌销售 我发布-->

      <!-- <div class="biddings">
       
        <div
          class="item"
          @click="ItemClick(item)"
          v-for="(item, index) in readlist"
          :key="index"
        >
          <div class="item_title">
            <div>发布时间：{{ item.createTime }}</div>
            <div
              class="title_but"
              @click="closeDemand"
              v-if="item.goodsStatus == 0"
            >
              关闭需求
            </div>
            <div class="title_but" v-if="item.goodsStatus == 1">进行中</div>
            <div class="title_but" v-if="item.goodsStatus == 2">已流拍</div>
            <div class="title_but" v-if="item.goodsStatus == 3">成功</div>
          </div>
          <div class="item_name">
            <div>
              商品名称：<span>{{ item.goodsName }}</span>
            </div>
            <div>
              价格：<span>{{ item.price }}元/吨</span>
            </div>
            <div>
              <span v-if="item.taxIncluded == 0">含税</span>
              <span v-if="item.taxIncluded == 1">不含税</span>
              <span>出厂价</span>
            </div>
          </div>
          <div class="item_num">
            <div>
              数量：<span>{{ item.stockCount }}(吨)</span>
            </div>
            <div>交货开始日期：{{ item.deliveryEndTime }}</div>
            <div>交货结束日期：{{ item.deliveryStartTime }}</div>
          </div>
          <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
          <div class="place">产地：{{ item.originPlace }}</div>
        </div>

        <div class="allpage" v-if="readlist.length > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="size"
            @current-change="handleCurrentChange"
            :total="total"
          >
          </el-pagination>
        </div>
      </div> -->
      <!-- 发布竞价销售信息 -->
    </div>
  </div>
</template>

<script>
import { tradeGoods, setclose } from "@/api/public.js";
export default {
  data() {
    return {
      readlist: [],
      size: 10,
      page: 1,
      total: 0,
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    addClick() {
      if (!this.beforhandle("personalCenter:ListingPurchase:publish:publish"))
        return;
      this.$router.push({
        path: "/addGoods",
        query: {
          selectOrderType: 2,
          type: "挂牌采购",
          title: "发布挂牌采购信息",
        },
      });
    },
    async handleCurrentChange(val) {
      let config = {
        page: val,
        size: this.size,
        selectOrderType: 2,
        selectTradeType: 1,
        selectType: 2,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.baddingEtartTime) {
            item.baddingEtartTime = item.baddingEtartTime.replace("T", " ");
          }
          if (item.baddingStartTime) {
            item.baddingStartTime = item.baddingStartTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.readlist = list;
        console.log(this.readlist,'22222222222222222222233333333333333333333333')
        this.total = res.data.total;
      }
    },
    async getlist() {
      let config = {
        page: this.page,
        size: this.size,
        selectOrderType: 2,
        selectTradeType: 1,
        selectType: 2,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.baddingEtartTime) {
            item.baddingEtartTime = item.baddingEtartTime.replace("T", " ");
          }
          if (item.baddingStartTime) {
            item.baddingStartTime = item.baddingStartTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
          if (item.price == "0.00") {
            item.price = 0;
          }
        });
        this.readlist = list;
        console.log(this.readlist,'222333')
        this.total = res.data.total;
      }
    },
    closeDemand(item) {
      if (!this.beforhandle("personalCenter:ListingPurchase:publish:publish:close")) return;
      this.$confirm("关闭后商品将无法交易 ，请确认是否关闭", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setclose(item.goodsId).then((res) => {
            // console.log(res);
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "关闭成功!",
              });
              this.getlist();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消关闭",
          });
        });
    },
    ItemClick(item) {
      if (!this.beforhandle("personalCenter:ListingPurchase:publish:publish:detail")) return;
      this.$router.push({
        path: "/purcDetails",
        query: {
          goodsId: item.goodsId,
          status: item.goodsStatus,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}

// .bidd {
//   width: 80%;
//   display: flex;
//   justify-content: space-between;
//   margin: 0 auto;
//   .biddings {
//     width: 70%;
//     .item {
//       width: 100%;
//       border: 1px solid black;
//       padding: 10px;
//       margin-top: 10px;
//       .item_title {
//         display: flex;
//         justify-content: space-between;
//         border-bottom: 1px solid #ccc;
//         padding-bottom: 10px;
//         .title_but {
//           padding: 10px;
//           border: 1px solid #ccc;
//           cursor: pointer;
//         }
//       }
//       .item_name {
//         display: flex;
//         margin-top: 5px;
//         :nth-child(1) {
//           width: 300px;
//         }
//         :nth-child(2) {
//           width: 300px;
//         }
//       }
//       .item_num {
//         :nth-child(1) {
//           width: 300px;
//         }
//         :nth-child(2) {
//           width: 300px;
//         }
//         display: flex;
//         margin-top: 10px;
//       }
//       .Delivery {
//         margin-top: 10px;
//       }
//       .place {
//         margin-top: 10px;
//       }
//     }
//   }
//   .allpage {
//     text-align: right;
//     margin-top: 10px;
//   }
//   .release {
//     height: 40px;
//     padding: 10px 20px;
//     border: 1px solid #ccc;
//     cursor: pointer;
//   }
// }
.bidd {
  // width: 80%;
  max-width: 1200px;
  // display: flex;
  // justify-content: space-between;
  margin: 0 auto;
  .biddings {
    margin-top: 20px;
    background-color: #ffffff;
    padding-bottom: 10px;
    // width: 70%;
    .biddings_title {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background: #e5e5e5;
      .biddings_name {
        font-size: 18px;
        font-weight: bold;
        line-height: 40px;
      }
    }
    .item {
      width: 100%;
      border: 1px solid #e5e5e5;
      padding: 10px;
      background: #fff;
      // margin-top: 10px;
      .item_title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        .item_goodsName {
          display: flex;
          line-height: 40px;
          align-content: center;
          :nth-child(1) {
            font-size: 18px;
            color: black;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 500px;
          }
          :nth-child(2) {
            margin-left: 13px;
          }
        }
        .title_but {
          padding: 10px;
          // border: 1px solid #ccc;
          cursor: pointer;
        }
      }
      .item_name {
        display: flex;
        // justify-content: space-between;
        margin-top: 5px;
        .item_images {
          width: 100px;
          height: 24px;
          background: url("../../assets/images/tag.png") no-repeat;
          background-size: 100% 100%;
          color: white;
          padding-left: 3px;
          padding-top: 2px;
          margin-top: 10px;
        }
        // :nth-child(1) {
        //   width: 300px;
        // }
        // :nth-child(2) {
        //   width: 300px;
        // }
      }
      .item_num {
        :nth-child(1) {
          width: 300px;
        }
        :nth-child(2) {
          width: 300px;
        }
        display: flex;
        margin-top: 10px;
        // justify-content: space-between;
      }
      .Delivery {
        margin-top: 10px;
      }
      .place {
        margin-top: 10px;
      }
    }
  }
  .allpage {
    text-align: right;
    margin-top: 10px;
  }
  .release {
    height: 40px;
    width: 200px;
    padding: 10px 20px;
    // border: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
  }
}
</style>
